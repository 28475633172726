<template>
  <div>
    <div class="u-bottom-margin--2x">
      <el-page-header
        @back="goBack()"
        :content="`Edit ${groupId} UserGroup`"
      ></el-page-header>
    </div>
    <el-form
      :model="addGroupForm"
      ref="addGroupForm"
      label-width="170px"
      :inline="false"
      size="mini"
    >
      <el-form-item label="Name">
        <el-input v-model="addGroupForm.name"></el-input>
      </el-form-item>
      <el-form-item label="Transaction number ">
        <span class="u-right-margin--2x">
          From:
          <el-input
            class="FromToOptionInput"
            size="mini"
            v-model="addGroupForm.T_MIN"
          >
            <template slot="prefix">
              <div style="padding-left:15px; color:black!important;">
                T-
              </div>
            </template></el-input
          >
        </span>
        <span class="u-right-margin--2x">
          To:
          <el-input
            class="FromToOptionInput"
            v-model="addGroupForm.T_MAX"
            size="mini"
          >
            <template slot="prefix">
              <div style="padding-left:15px; color:black!important;">
                T-
              </div>
            </template></el-input
          >
        </span>
      </el-form-item>
      <el-form-item label="Sum of transactions ">
        <span class="u-right-margin--2x">
          From:
          <el-input
            class="FromToOptionInput"
            size="mini"
            v-model="addGroupForm.minSumTrans"
          ></el-input>
        </span>
        <span class="u-right-margin--2x">
          To:
          <el-input
            class="FromToOptionInput"
            v-model="addGroupForm.maxSumTrans"
            size="mini"
          ></el-input>
        </span>
        <el-select
          class="u-right-margin--2x"
          clearable
          @change="PeriodSelected($event, 'sum', addGroupForm)"
          v-model="addGroupForm.sumPeriod"
          placeholder="Select a period"
        >
          <el-option key="All" label="All" value="All"> </el-option>
          <el-option key="week" label="1 Week" value="week"> </el-option>
          <el-option key="twoWeeks" label="2 Weeks" value="twoWeeks">
          </el-option>
          <el-option key="month" label="Month" value="month"> </el-option>
          <el-option
            key="certinPeriod"
            label="Certin Period"
            value="certinPeriod"
          >
          </el-option>
        </el-select>
        <span v-if="addGroupForm.sumCPeriod">
          From:
          <el-date-picker
            class="picker u-right-margin--2x"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="addGroupForm.sumStartDate"
            type="datetime"
            placeholder="Start date"
            align="right"
          >
          </el-date-picker>
          To:
          <el-date-picker
            class="picker"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="addGroupForm.sumEndDate"
            type="datetime"
            placeholder="End date"
            align="right"
          >
          </el-date-picker>
        </span>
      </el-form-item>
      <el-form-item label="Avg transaction amount">
        <span class="u-right-margin--2x">
          From:
          <el-input
            class="FromToOptionInput"
            size="mini"
            v-model="addGroupForm.minAvgTrans"
          ></el-input>
        </span>
        <span class="u-right-margin--2x">
          To:
          <el-input
            class="FromToOptionInput"
            v-model="addGroupForm.maxAvgTrans"
            size="mini"
          ></el-input>
        </span>
        <el-select
          class="u-right-margin--2x"
          clearable
          @change="PeriodSelected($event, 'avg', addGroupForm)"
          v-model="addGroupForm.avgPeriod"
          placeholder="Select a period"
        >
          <el-option key="All" label="All" value="All"> </el-option>
          <el-option key="week" label="1 Week" value="week"> </el-option>
          <el-option key="twoWeeks" label="2 Weeks" value="twoWeeks">
          </el-option>
          <el-option key="month" label="Month" value="month"> </el-option>
          <el-option
            key="certinPeriod"
            label="Certin Period"
            value="certinPeriod"
          >
          </el-option>
        </el-select>
        <span v-if="addGroupForm.avgCPeriod">
          From:
          <el-date-picker
            class="picker u-right-margin--2x"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="addGroupForm.avgStartDate"
            type="date"
            placeholder="Start date"
            align="right"
          >
          </el-date-picker>
          To:
          <el-date-picker
            class="picker"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="addGroupForm.avgEndDate"
            type="date"
            placeholder="End date"
            align="right"
          >
          </el-date-picker>
        </span>
      </el-form-item>
      <el-form-item label="num of transactions">
        <span class="u-right-margin--2x">
          From:
          <el-input
            class="FromToOptionInput"
            size="mini"
            v-model="addGroupForm.minNumOfTrans"
          ></el-input>
        </span>
        <span class="u-right-margin--2x">
          To:
          <el-input
            class="FromToOptionInput"
            v-model="addGroupForm.maxNumOfTrans"
            size="mini"
          ></el-input>
        </span>
        <el-select
          class="u-right-margin--2x"
          clearable
          @change="PeriodSelected($event, 'numTrans', addGroupForm)"
          v-model="addGroupForm.numTransPeriod"
          placeholder="Select a period"
        >
          <el-option key="All" label="All" value="All"> </el-option>
          <el-option key="week" label="1 Week" value="week"> </el-option>
          <el-option key="twoWeeks" label="2 Weeks" value="twoWeeks">
          </el-option>
          <el-option key="month" label="Month" value="month"> </el-option>
          <el-option
            key="certinPeriod"
            label="Certin Period"
            value="certinPeriod"
          >
          </el-option>
        </el-select>
        <span v-if="addGroupForm.numTransCPeriod">
          From:
          <el-date-picker
            class="picker u-right-margin--2x"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="addGroupForm.numTransStartDate"
            type="date"
            placeholder="Start date"
            align="right"
          >
          </el-date-picker>
          To:
          <el-date-picker
            class="picker"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="addGroupForm.numTransEndDate"
            type="date"
            placeholder="End date"
            align="right"
          >
          </el-date-picker>
        </span>
      </el-form-item>
      <el-form-item label="Reigons">
        <el-select
          class="area-dropdown"
          v-if="lookups"
          filterable
          :filter-method="filterByBigRegion"
          v-model="addGroupForm.regions"
          multiple
          placeholder="select regions"
          @click.native="onSelectClick"
        >
          <div :key="reigon.id" v-for="reigon in filteredReigonOptions">
            <el-collapse accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <el-option
                    :class="{ 'u-font--large': true, 'u-font--bold': true }"
                    :key="reigon.id"
                    :label="reigon.name"
                    @click.native="onBigReigonClick(reigon.id, addGroupForm)"
                    :value="reigon.id"
                  ></el-option>
                </template>
                <template>
                  <el-option
                    v-for="cReigon in reigon.childs"
                    @click.native="isBigReigon = false"
                    :disabled="checkReigon(reigon.id, addGroupForm)"
                    :class="{ 'u-font--xsmall': true }"
                    :key="cReigon.id"
                    :label="cReigon.name"
                    :value="cReigon.id"
                  >
                  </el-option
                ></template>
              </el-collapse-item>
            </el-collapse>
          </div>
          <!-- </template> -->
        </el-select>
      </el-form-item>
      <el-form-item label="Wholesaler">
        <el-select
          class="area-dropdown"
          v-model="addGroupForm.providers"
          v-if="lookups"
          multiple=""
          filterable
          placeholder="Select Providers"
          clearable
        >
          <el-option
            v-for="item in lookups.distributers"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Users date created">
        <el-select
          class="u-right-margin--2x"
          clearable
          @change="PeriodSelected($event, 'user', addGroupForm)"
          v-model="addGroupForm.userPeriod"
          placeholder="Select a period"
        >
          <el-option key="All" label="All" value="all"> </el-option>
          <el-option key="week" label="1 Week" value="week"> </el-option>
          <el-option key="twoWeeks" label="2 Weeks" value="twoWeeks">
          </el-option>
          <el-option key="month" label="Month" value="month"> </el-option>
          <el-option
            key="certinPeriod"
            label="Certin Period"
            value="certinPeriod"
          >
          </el-option>
        </el-select>
        <span v-if="addGroupForm.userCPeriod">
          From:
          <el-date-picker
            class="picker u-right-margin--2x"
            v-model="addGroupForm.userStartDate"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="Start date"
            align="right"
          >
          </el-date-picker>
          To:
          <el-date-picker
            class="picker"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="addGroupForm.userEndDate"
            type="date"
            placeholder="End date"
            align="right"
          >
          </el-date-picker>
        </span>
      </el-form-item>
    </el-form>
    <div class="u-display-flex u-justify-content--center">
      <el-button type="primary" @click="editForm">Edit</el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { userGroupService } from "../../services/userGroup.service";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filteredReigonOptions: [],

      list: [],
      userGroups: [],

      addGroupForm: {
        providers: [],
      },
      addDialogVisable: false,
    };
  },
  watch: {
    lookups: {
      immediate: true,
      handler(value) {
        console.log(value.regions);
        this.filteredReigonOptions = [...value.regions];
      },
    },
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
  created() {
    this.getGroup();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async editForm() {
      let isValid = await this.$refs["addGroupForm"].validate();
      if (isValid) {
        let pageLoading = this.$loading();
        let userGroupObj = { ...this.addGroupForm };
        this.cleanObj(userGroupObj);
        // userGroupObj.itemsPerUnit = `${productObj.itemsPerUnit} X`;
        try {
          await userGroupService.editUserGroup(this.groupId, userGroupObj);

          this.$notify({
            title: "Success",
            message: "This UserGroup is  Created sucessufly",
            type: "success",
          });
        } catch (err) {
          console.log("error");

          this.$notify({
            title: "Error ",
            message: err.response.data.error,
            type: "error",
          });
        }
        pageLoading.close();
      }
    },
    cleanObj(obj) {
      let arrKey = Object.keys(obj);
      for (let i = 0; i < arrKey.length; i++) {
        const element = arrKey[i];

        if (element.includes("CPeriod")) {
          let IsCPeriod = obj[element];
          obj[element] = undefined;
          let key = element.replace("CPeriod", "");
          if (!IsCPeriod) {
            if (_.isEmpty(obj[`${key}Period`])) {
              obj[`${key}Period`] = undefined;
            }

            continue;
          }
          obj[`${key}Period`] = undefined;
        }
      }
    },
    PeriodSelected(value, key) {
      if (value == "certinPeriod") {
        this.addGroupForm[`${key}CPeriod`] = true;
        return;
      }
      if (this.addGroupForm[`${key}StartDate`] != undefined)
        this.addGroupForm[`${key}StartDate`] = undefined;
      if (this.addGroupForm[`${key}EndDate`] != undefined)
        this.addGroupForm[`${key}EndDate`] = undefined;
      this.addGroupForm[`${key}CPeriod`] = false;
    },

    checkReigon(id, obj) {
      const regions = obj.regions;
      if (regions != null) return obj.regions.includes(id);
      else return false;
    },
    onBigReigonClick(id, obj) {
      const reigon = this.lookups.regions.filter((item) => item.id == id);

      if (reigon) {
        for (let i = 0; i < reigon[0].childs.length; i++) {
          const element = reigon[0].childs[i];
          const exist = obj.regions.includes(element.id);
          if (exist) {
            const index = obj.regions.indexOf(element.id);
            if (index > -1) {
              obj.regions.splice(index, 1);
            }
          }
        }
      }
    },
    onSelectClick() {
      this.filteredReigonOptions = this.lookups.regions;
    },
    filterByBigRegion(val) {
      if (val) {
        this.filteredReigonOptions = this.lookups.regions.filter((reigon) => {
          return reigon.name.includes(val);
        });
      } else {
        this.filteredReigonOptions = this.lookups.regions;
      }
    },
    getGroup() {
      this.$loading();
      userGroupService
        .getSingleUserGroup(this.groupId)
        .then((res) => {
          console.log(res);
          this.addGroupForm = res.data.userGroup;

          let objKeys = Object.keys(this.addGroupForm);
          for (let i = 0; i < objKeys.length; i++) {
            const element = objKeys[i];
            if (element.includes("Period")) {
              let key = element.replace("Period", "");
              if (
                this.addGroupForm[`${key}StartDate`] != null ||
                this.addGroupForm[`${key}EndDate`] != null
              ) {
                this.addGroupForm[`${key}Period`] = "certinPeriod";
                this.addGroupForm[`${key}CPeriod`] = true;
              }
            }
          }
        })
        .finally(() => {
          this.$loading().close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.FromToOptionInput {
  display: inline-block !important;

  width: 100px !important;
}
.picker {
  width: 130px !important;
}
</style>
